// MAIN SECTION
section.home-section {
  background-image: url(/img/new-design/tuna-background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  font-family: 'Ubuntu', sans-serif;
  position: relative;

  //NAV
  .home-section__main-nav {
    height: 60px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    color: #fff;
    position: relative;

    .main-nav__nav-items {
      display: flex;
      height: 16px;
      align-items: center;
      cursor: pointer;
      
      .nav-items__menu-icon {
        margin-right: 14px;
      }

      .nav-items__links {
        display: flex;
        align-items: center;
        margin-left: 20px;
        font-size: 14px;
      }
      
      .nav-items__menu-arrow {
        margin-right: 4px;
      }

      .nav-items__text{
        color: #fff;
      }

    }

    .main-nav__nav-items--mobile-logo {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  // INFO
  .home-section__info {
    color: #fff;
    margin-top: 66px;

    @media(min-width:  $lg) {
      margin-top: 71px;
    }

    .info__info-title {
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 32px;

      @media(min-width:  $lg) {
        font-size: 70px;
        margin-bottom: 24px;
      }

      span {
        color: $gold;
      }
    }

    .info__info-text {
      max-width: 740px;
      font-size: 20px;
      margin: 0 auto 63px;

      @media(min-width:  $lg) {
        margin: 0 auto 100px;
      }
    }
  }
}

//PRODUCTS
section.home-section__products {
  background-color: $dark-blue;
  color: #fff;
  padding-block: 60px 53px;
  position: relative;

  @media(min-width: $lg) {
    padding-block: 60px 174px;
  }

  .products__logo {
    width: 173px;
    margin: 0 auto 46px;
  }

  .products__text {
    max-width: 740px;
    margin: 0 auto 60px;
    font-size: 20px;
  }

  // CARDS
  .products__product-card {
    position: relative;
    z-index: 1100; // over bootstrap modal backdrop

    .product-card__product {
      height: 100%;
      max-width: 407px;
      color: $dark-blue;
      background-color: #fff;
      padding: 20px 20px 78px;
      border-radius: 12px;
      align-self: stretch;
      position: relative;
      margin: 0 auto;

      .product__image {
        position: relative;

        .image__item {
          width: 100%;
          margin-bottom: 12px;
          cursor: pointer;
        }

        .image__slider-button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        .image__slider-button.slider-prev {
          left: 12px;
        }

        .image__slider-button.slider-next {
          right: 12px;
        }

      }

      .product__title {
        font-size: 20px;
        margin-bottom: 16px;
        font-weight: 500;
      }

      .product__list {
        list-style: none;
        padding: 0;
        margin-bottom: 24px;
        font-size: 14px;

        .list__item {
          margin-bottom: 12px;
          display: flex;
          align-items: flex-start;

          img {
            margin-right: 12px;
          }
        }
      }

      .product__prices {
        list-style: none;
        padding: 20px 90px 20px 20px;
        background-color: #f7f7f7;
        font-size: 14px;
        border-radius: 14px;
        margin-bottom: 20px;

        .prices__item {
          line-height: 30px;
        }
      }

      .product__notes {
        font-size: 12px;
        margin-bottom: 21px;
      }

      .product__link {
        width: calc(100% - 40px);
        display: grid;
        place-items: center;
        height: 54px;
        border-radius: 8px;
        background: $gold;
        color: $dark-blue;
        position: absolute;
        bottom: 20px;
        left: 20px;
        border: none;

        &:hover {
          background-color: #ebbe00;
        }
      }
    }
  }

  // MODAL
  .modal {
    color: $dark-blue;

    .modal-dialog {
      max-width: 844px;
    }

    .modal-content {
      padding: 30px;
      position: relative;

      .btn-close {
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }

    .modal-header,
    .modal-body {
      padding: 0;
      border: none;
    }
    
    .modal__title {
      width: 100%;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 28px;
    }

    .modal-video-slider {
      width: 100%;
      border-radius: 5px;

      .carousel {
        width: 100%;
        height: 100%;
      }
    }
  }

  // ELLIPSE IMAGES
  .ellipse {
    position: absolute;

    &.ellipse-big-left {
      left: 0;
      top: 250px;
      transform: rotate(180deg);
    }

    &.ellipse-big-right {
      right: 0;
      top: 480px;
    }

    &.ellipse-medium {
      left: 70px;
      top: 100px;
    }

    &.ellipse-small-left {
      left: 190px;
      top: 720px;
    }

    &.ellipse-small-right {
      right: 174px;
      top: 450px;
    }
  }
}

// HOME MENU
.home-menu {
  position: fixed;
  top: 0;
  width: clamp(351px, 95%, 375px);
  min-height: 100vh;
  font-size: 18px;
  font-weight: 400;
  color: #0c2340;
  background-color: #fafafa;
  padding: 64px 40px 40px 50px;
  list-style: none;
  z-index:1;
  left: -100%;
  transition: 0.6s all ease-in-out;

  &.active {
    left: 0;
  }

  .home-menu__close-icon {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
  }

  .home-menu__item {
    margin-block: 32px;
    cursor: pointer;

    &:hover, &:active {
      font-weight: 600;
    }

    &:first-child {
      color: #ffce00;
      font-weight: 500;
    }

    a {
      color: inherit;
      font-weight: inherit;
      font-size: inherit;
    }
  }

  @media(min-width: 992px) {
    padding: 100px 40px 40px 80px;
  }
}