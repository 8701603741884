section.header-section {
  background-color: $blue-light;
  font-family: "Ubuntu", sans-serif;

  //NAV
  .header-section__main-nav {
    height: 139px;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    padding: 40px 0 0;
    list-style: none;
    color: #fff;
    position: relative;

    @media (min-width: $lg) {
      height: 167px;
    }

    .main-nav__nav-items {
      display: flex;
      height: 16px;
      align-items: center;
      cursor: pointer;

      .nav-items__links {
        color: #fff;
        display: flex;
        align-items: center;
        margin-left: 20px;
      }

      .nav-items__menu-arrow {
        margin-right: 4px;
      }

      .nav-items__text {
        font-size: 14px;
      }
    }

    .main-nav__nav-logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

section.steps-section {
  font-family: "Ubuntu", sans-serif;

  .header-section__steps-list {
    height: 68px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;

    .steps-list__item {
      display: flex;
      align-items: center;

      .item__number {
        margin-right: 8px;
      }

      .item__text {
        font-size: 14px;
        font-weight: 500;
        padding-bottom: 2px;
        color: $grey-text;
      }
    }

    // clase para cambiar colores a los elementos activados
    .done_element {
      .item__number {
        color: $dark-blue;
      }

      .item__text {
        font-size: 14px;
        font-weight: 500;
        padding-bottom: 2px;
        color: $dark-blue;
      }
    }

    // clase que pone el subrayado amarillo al paso activo
    .active {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: $gold;
      }
    }
  }
}

section.informacion-section {
  min-height: calc(100vh - 208px);
  background: $dark-blue;
  font-family: "Ubuntu", sans-serif;
  padding-block: 32px;

  @media (min-width: $lg) {
    min-height: calc(100vh - 235px);
    padding-block: 60px;
  }

  // FIRST COLUMN
  .informacion-section__first-col {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  .margin-40 {
    margin-top: 40px;
  }

  .margin-32 {
    margin-top: 32px;
  }

  .form__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 34px;
    margin-bottom: 32px;
  }

  .title-calendar-link {
    width: 100%;
    height: 38px;
    color: #fff;
    display: grid;
    place-items: center;
    border: 1px solid $blue-border;
    border-radius: 8px;
    background-color: transparent;
    padding-left: 15px;
    padding-right: 15px;

    &:hover {
      background-color: #0a6cA3;
    }
  }

  .check__option {
    outline: 1px solid #46586f;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 4px;
    padding: 18px 28px;
    margin-bottom: 20px;
    opacity: 0.5;
    cursor: pointer;

    .info__text {
      margin-left: 20px;
      margin-top: 16px;

      @media (min-width: $lg) {
        margin-left: 0;
        margin-top: 0;
      }
    }

    &.orange {
      label {
        color: #ffa800 !important;
      }

      input[type="radio"]:checked + label {
        background: url(/img/new-design/checkbox-orange.svg) left top no-repeat;
      }
    }

    &.green {
      label {
        color: #2f994d !important;
      }

      input[type="radio"]:checked + label {
        background: url(/img/new-design/checkbox-green.svg) left top no-repeat;
      }
    }

    &.blue {
      label {
        color: #0a6ca3 !important;
      }

      input[type="radio"]:checked + label {
        background: url(/img/new-design/checkbox-blue.svg) left top no-repeat;
      }
    }

    &:last-of-type {
      margin-bottom: 40px;
    }
  }

  .check__option--active {
    background: rgba(255, 255, 255, 0.06);
    opacity: 1;

    &.orange {
      outline: 3px solid #ffa800;
    }

    &.green {
      outline: 3px solid #2f994d;
    }

    &.blue {
      outline: 3px solid #0a6ca3;
    }
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + label {
    background: url(/img/new-design/checkbox-unchecked.svg) left top no-repeat;
    background-size: 20px 20px;
    min-height: 20px;
    margin-left: -30px;
    padding-left: 30px;
  }

  .margin-sm-32 {
    margin-top: 32px;

    @media (min-width: $lg) {
      margin-top: 0;
    }
  }

  .form-control--custom {
    background-color: transparent;
    border: 1px solid #46586f;
    color: #ffffff;

    &:hover {
      border: 1px solid #0a6ca3;
    }

    &:focus {
      border: 2px solid #0a6ca3;
      box-shadow: none;
    }

    &::placeholder {
      color: #46586f;
    }
  }

  input[type="number"] {
    background: url(/img/new-design/increment-decrement.svg) right 12px top 12px no-repeat;
    z-index: 0;
    -moz-appearance: textfield;
  }

  input[type="number"]::-webkit-inner-spin-button {
    opacity: 0;
    z-index: 2;
    height: 50px;
    cursor: pointer;
  }

  @-moz-document url-prefix() {
    input[type="number"] {
      background: none;
    }
  }

  input[type="number"],
  input[type="text"],
  input[type="tel"],
  input[type="email"] {
    height: 64px;
  }

  textarea {
    height: 220px;
  }

  // TOTAL PRICE
  .first-col__total-price {
    margin-block: 24px 12px;

    .total-price__price,
    .total-price__discount {
      margin-bottom: 12px;

      .price__title,
      .discount__title {
        color: #b4b4b5;
      }
    }
  }

  .accept-total__total-submit {
    .total-submit__total-price {
      .total-price__title {
        color: #b4b4b5;
        margin-bottom: 7px;
      }

      .total-price__price {
        font-size: 32px;
        font-weight: 500;

        span {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    .total-submit__submit-button {
      width: clamp(200px, 100%, 400px);
      height: 54px;
      font-size: 14px;
      color: $dark-blue;
      background-color: $gold;
      border: none;
      border-radius: 8px;
      margin: 32px auto 0;
      text-transform: uppercase;

      @media (min-width: $lg) {
        width: 263px;
        margin: 40px 0 0;
      }

      &:hover {
        background-color: #ebbe00;
      }
    }
  }

  // SECOND COLUMN
  .informacion-section__second-col {
    .product-card__product {
      max-width: 100%;
      height: 90px;
      color: $dark-blue;
      background-color: #fff;
      margin-bottom: 32px;
      padding: 24px 21px 66px;
      border-radius: 12px;
      position: relative;
      margin: 47px 0 0;
      overflow: hidden;
      cursor: pointer;

      @media (min-width: $lg) {
        max-width: 407px;
        margin: 0 auto;
        height: auto;
        padding: 16px 16px 20px;
        cursor: initial;
      }

      .product__arrow-icon {
        position: absolute;
        right: 16px;
      }

      .product__title {
        font-size: 20px;
        margin-bottom: 6px;
        font-weight: 500;

        @media (min-width: $lg) {
          margin-bottom: 16px;
        }
      }

      .product__sub-title{
        font-size: 14px;
      }

      .product__image {
        width: 100%;
        margin-bottom: 12px;
        margin-top: 100px;

        @media (min-width: $lg) {
          margin-top: auto;
        }
      }

      .product__list {
        list-style: none;
        padding: 0;
        margin-bottom: 24px;
        font-size: 14px;

        .list__item {
          margin-bottom: 12px;
          display: flex;
          align-items: flex-start;

          img {
            margin-right: 12px;
          }
        }
      }

      .product__notes {
        font-size: 12px;
        margin-bottom: 21px;
      }
    }

    .second-col__image {
      margin-top: 91px;

      img {
        width: 100%;
      }
    }
  }
}
