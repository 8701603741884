section.confirmacion-section {
  min-height: calc(100vh - 208px);
  background: $dark-blue;
  font-family: 'Ubuntu', sans-serif;
  padding-block: 60px;

  @media (min-width: $lg) {
    min-height: calc(100vh - 235px);
  }

  // FIRST COLUMN
  .confirmacion-section__first-col {
    color: #fff;

    .first-col__success-sentence {
      display: flex;
      align-items: center;

      .success-sentence__text {
        font-size: 20px;
        padding-bottom: 12px;
        margin-left: 8px;
      }
    }

    .first-col__button {
      width: 218px;
      height: 54px;
      color: $dark-blue;
      background-color: $gold;
      border-radius: 8px;
      margin-block: 40px 135px;

      &:hover {
        background-color: #ebbe00;
      }

      img {
        margin-right: 8px;
      }
    }

    .first-col__image {
      margin: 0 auto;
    }
  }

  // SECOND COLUMN
  .confirmacion-section__second-col {
    .product-card__product {
      max-width: 407px;
      color: $dark-blue;
      background-color: #fff;
      padding: 16px 16px 20px ;
      border-radius: 12px;
      position: relative;
      margin: 0 auto;

      .product__title {
        font-size: 20px;
        margin-bottom: 16px;
        font-weight: 500;
      }

      .product__image {
        width: 100%;
        margin-bottom: 12px;
      }

      .product__list {
        list-style: none;
        padding: 0;
        margin-bottom: 24px;
        font-size: 14px;

        .list__item {
          margin-bottom: 12px;
          display: flex;
          align-items: flex-start;

          img {
            margin-right: 12px;
          }
        }
      }

      .product__notes {
        font-size: 12px;
        margin-bottom: 21px;
      }
    }

    .second-col__image {
      margin-top: 91px;

      img {
        width: 100%;
      }
    }
  }
}