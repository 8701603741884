a {
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}

p {
  margin: 0;
}

section > * {
  max-width: $max-width;
}

// Bootstrap Overrides
.carousel {
  margin-bottom: 45px;

  .carousel-control-prev {
    opacity: 1;

    &:hover {
      cursor: initial;
    }

    .carousel-control-prev-icon {
      width: 42px;
      height: 42px;
      background-size: contain;
      background-image: url("/img/new-design/slide-left.svg");

      &:hover {
        opacity: 0.6;
        cursor: pointer;
      }
    }
  }
  .carousel-control-next {
    opacity: 1;

    &:hover {
      cursor: initial;
    }

    .carousel-control-next-icon {
      width: 42px;
      height: 42px;
      background-size: contain;
      background-image: url("/img/new-design/slide-right.svg");

      &:hover {
        opacity: 0.6;
        cursor: pointer;
      }
    }
  }
  .carousel-indicators {
    bottom: -65px;

    button {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background-color: #EBEBEB;
      opacity: 1;

      &.active {
        background-color: #0A6CA3;
      }
    }
  }
}
.modal {
  .modal-header {
    border-bottom: 1px solid #d3d3d3;
  }
}