section.resumen-section {
  background: $dark-blue;
  font-family: 'Ubuntu', sans-serif;
  padding-block: 60px;

  // FIRST COLUMN
  .resumen-section__first-col {
    color: #fff;

    // FIRST COL TITLE
    .first-col__title-edit {
      margin-bottom: 30px;

      .title-edit__title {
        font-size: 20px;
        font-weight: 600;
      }

      .title-edit__edit {
        width: 104px;
        height: 38px;
        color: #fff;
        display: grid;
        place-items: center;
        border: 1px solid $blue-border;
        border-radius: 8px;
        background-color: transparent;

        &:hover {
          background-color: #0a6cA3;
        }
      }
    }

    // FIRST COL RESUME INFO
    .first-col__resume-info {
      padding: 24px 24px 0;
      background-color: $background-grey-translucid;
      border-radius: 12px;

      .resume-info__item {
        margin-bottom: 32px;

        h3 {
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 12px
        }

        p {
          color: #cdcdcd;
        }
      }
    }

    // FIRST COL TOTAL PRICE
    .first-col__total-price {
      margin-block: 24px 12px;

      .total-price__price,
      .total-price__discount {
        margin-bottom: 12px;

        .price__title,
        .discount__title {
          color: #b4b4b5;
        }
      }
    }

    // FRST COL ACCEPT TOTAL
    .first-col__accept-total {
      margin-top: 32px;

      @media (min-width: $lg) {
        margin-top: auto;
      }

      .form-check {
        margin-bottom: 34px;

        .accept-terms-conditions {
          font-weight: bold;
          text-decoration: underline;
          color: inherit
        }
      }

      .accept-total__total-submit{
        .total-submit__total-price {
          .total-price__title {
            color: #b4b4b5;
            margin-bottom: 7px;
          }

          .total-price__price {
            font-size: 32px;

            span {
              font-size: 12px;
            }
          }
        }

        .total-submit__submit-button {
          width: clamp(200px, 100%, 400px);
          height: 54px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          color: $dark-blue;
          background-color: $gold;
          border: none;
          border-radius: 8px;
          margin: 32px auto 0;

          @media (min-width: $lg) {
            width: 263px;
            margin: 6px 0 0;
          }

          &:hover {
            background-color: #ebbe00;
          }

          img {
            margin-right: 8px;
          }

          &.total-submit__payment-button {
            max-width: 100%;
            width: 159px;

            p {
              color: #0C2340;
              font-weight: 500;
            }
          }
        }

        .modal {
          color: $dark-blue;

          .modal-dialog {
            max-width: 625px;
          }

          .modal-content {
            padding: 40px;
            position: relative;

            .btn-close {
              position: absolute;
              top: 20px;
              right: 20px;
            }
          }

          .modal-header,
          .modal-body {
            padding: 0;
          }

          .modal__title {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 28px;
          }

          .modal__sub-title {
            font-size: 18px;
            font-weight: 500;
            margin-top: 28px;
          }

          .modal__text {
            margin-block: 24px;
            color: #46586f;
          }
        }
      }
    }
  }

  // SECOND COLUMN
  .resumen-section__second-col {
    .product-card__product {
      max-width: 407px;
      color: $dark-blue;
      background-color: #fff;
      margin-bottom: 32px;
      padding: 16px 16px 20px ;
      border-radius: 12px;
      position: relative;
      margin: 0 auto;

      .product__title {
        font-size: 20px;
        margin-bottom: 16px;
        font-weight: 500;
      }

      .product__image {
        width: 100%;
        margin-bottom: 12px;
      }

      .product__list {
        list-style: none;
        padding: 0;
        margin-bottom: 24px;
        font-size: 14px;

        .list__item {
          margin-bottom: 12px;
          display: flex;
          align-items: flex-start;

          img {
            margin-right: 12px;
          }
        }
      }

      .product__notes {
        font-size: 12px;
        margin-bottom: 21px;
      }
    }

    .second-col__image {
      margin-top: 91px;

      img {
        width: 100%;
      }
    }
  }
}