@import 'new-design/global/colors';
@import 'new-design/global/fonts';
@import 'new-design/global/sizes';
@import 'new-design/global/global';
@import 'new-design/home';
@import 'new-design/information';
@import 'new-design/summary';
@import 'new-design/confirmation';

.btn.btn-change-lang {
  background-color: transparent;
  border: none;
  align-items: center;
  margin-left: 20px;
  font-size: 14px;

  &::after {
    content: unset;
  }

  &::before {
    display: inline-block;
    vertical-align: middle;
    content: "";
    background-image: url('/img/new-design/arrow-down-white.png');
    width: 14px;
    height: 14px;
    position: relative;
    top: -2px;
    left: -2px;
  }

  &:focus,
  &:active {
    outline: 0;
    box-shadow: none;
  }
}